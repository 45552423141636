<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title> Perfil </v-card-title>
          <v-card-text>
            <div>
              Usuario:
              <span class="font-weight-bold">{{
                current_user.usuarioAlias
              }}</span>
            </div>
            <div>
              Rol:
              <span class="font-weight-bold">{{
                fnGetRolDescription(current_user.rol)
              }}</span>
            </div>
            <div class="mt-1" v-if="current_user.rol == 0">
              Usuarios:
              <span class="font-weight-bold">{{ tblTotalRegs }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col>
        <v-card>
          <v-card-title> Datos de la empresa </v-card-title>
          <v-card-text>
            <div>
              Nombre de la empresa:
              <span class="font-weight-bold">{{
                current_user.plan.empresa
              }}</span>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    @mouseover="direccion_hover = true"
                    @mouseleave="direccion_hover = false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dirección:
                    <span class="font-weight-bold">{{
                      current_user.plan.direccion
                    }}</span>
                    <br />
                    Teléfono:
                    <span class="font-weight-bold">{{
                      current_user.plan.telefono
                    }}</span>
                  </div>
                </template>
                <v-row align="center" justify="center">
                  <v-col
                    class="text-center"
                    align="center"
                    justify="center"
                    md="auto"
                  >
                    <span
                      >Esta información se despliega en esta sección <br />
                      dentro de la aplicación:</span
                    >
                    <v-img
                      class="ml-5"
                      max-height="250"
                      max-width="250"
                      :src="require('@/assets/imagen_direccion.png')"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-tooltip>
            </div>
          </v-card-text>
          <v-card-actions v-if="current_user.rol == 0">
            <v-spacer> </v-spacer>
            <v-btn @click="fnShowDialogEditarEmpreaInfo()" align="right" text
              >editar información</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row v-if="current_user.rol == 0">
      <!-- TABLA DE REGISTROS DE PILOTOS CREADOS -->
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              :loading="loadingTBL"
              :disable-sort="loadingTBL"
              :headers="headers"
              :items="tblRegs"
              :options.sync="options"
              :server-items-length="tblRegs.length"
              disable-pagination
              :hide-default-footer="true"
              no-data-text="Sin registros que mostrar"
              no-results-text="No se encontrarón registros"
            >
              <!-- /*** ID DE LA GUÍA */ -->
              <template v-slot:[`item._id`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      @click="copyToClipBoard(item._id)"
                      v-bind="attrs"
                      v-on="on"
                      >{{ item._id | truncate(6, '...') }}</span
                    >
                  </template>
                  <span>{{ item._id }}</span>
                </v-tooltip>
              </template>
              <!-- /*** ID DE LA GUÍA */ -->
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ fnGetDateUtc(item.createdAt) }}
              </template>

              <template v-slot:[`item.updatedAt`]="{ item }">
                {{ fnGetDate(item.updatedAt) }}
              </template>

              <template v-slot:[`item.rol`]="{ item }">
                {{ fnGetRolDescription(item.rol) }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <!-- /** VER INFORMACIÓN DEL PAQUETE */ -->
                <!-- <modalInformacionDeGuia :guiaid="item._id" /> -->
                <!-- <modalAnularGuia :guiaid="item._id" /> -->
                <!-- /** EDITAR PAQUETE */ -->
                <!-- /** OPCIONES DE ANULAR Y DEVOLVER PAQUETE */ -->
                <!-- <v-icon small @click="eliminarPaquete(item.id)">
                mdi-keyboard-return
                </v-icon>
                <v-icon small @click="eliminarPaquete(item.id)">
                mdi-cancel
                </v-icon> -->
                <v-icon
                  title="Editar"
                  class="mr-2"
                  small
                  @click="fnAssignEditUsuario(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  title="Eliminar"
                  class="mr-2"
                  small
                  @click="fnAssignEliminarusuario(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <v-fab-transition>
              <v-btn
                @click="dialog_crear_usuario = true"
                class="mb-25"
                dark
                absolute
                bottom
                right
                fab
                title="Crear guía"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Registros por página"
                  @change="handlePageSizeChange"
                ></v-select>
              </v-col>
              <v-col>
                <v-pagination
                  v-model="page"
                  :length="totalPages"
                  total-visible="7"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                  @input="handlePageChange"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- TABLA DE REGISTROS DE PILOTOS CREADOS -->
    </v-row>

    <!-- /** DIALOG PARA EDICIÓN DE USUARIO */ -->
    <v-dialog
      v-model="dialog_editar_usuario"
      width="50%"
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog_editar_usuario = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="editUsuario">{{
          editUsuario.usuarioAlias
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog_editar_usuario = false">
            Cerrar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!-- INICIO FORMULARIO EDICION DE USUARIO -->
      <v-card v-if="editUsuario != null">
        <v-card-title class="text-h5">
          Editar información de usuario
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-form ref="form_edit" v-model="valid2" lazy-validation>
                <v-text-field
                  @keydown.space.prevent
                  v-model="editUsuario.usuarioAlias"
                  label="Alias"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="editUsuario.usuarioNombre"
                  label="Nombre del usuario"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="editUsuario.usuarioEmail"
                  label="Correo electrónico"
                  @keydown.space.prevent
                ></v-text-field>

                <v-select
                  v-model="editUsuario.rol"
                  :items="roles"
                  item-text="rol_descripcion"
                  item-value="rol_id"
                  :rules="[(v) => v != null || 'Este campo es obligatorio']"
                  label="Rol de usuario"
                  required
                  return-object
                >
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    <div style="width: 100%" :title="data.item.restricciones">
                      {{ data.item.rol_descripcion }}
                    </div>
                  </template>
                </v-select>
                <div>
                  Editar contraseña
                  <v-checkbox
                    label="¿Desea cambiar la contraseña?"
                    v-model="check_editar_contraseña"
                    color="yellow"
                  >
                  </v-checkbox>
                </div>
                <div v-if="check_editar_contraseña">
                  <v-text-field
                    ref="edit_usuarioPassword"
                    :type="fieldTypes.password"
                    v-model="edit_usuarioPassword"
                    label="Nueva contraseña"
                  ></v-text-field>

                  <v-text-field
                    ref="repeat_usuarioPassword"
                    :type="fieldTypes.password"
                    v-model="repeat_usuarioPassword"
                    label="Repite la nueva contraseña"
                  ></v-text-field>
                </div>

                <!-- <v-btn color="warning" @click="resetValidation">
                    Reset Validation
                  </v-btn> -->
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            bottom
            right
            small
            color=" darken-1"
            @click="reset, (dialog_editar_usuario = false)"
          >
            Cancelar
          </v-btn>

          <v-btn
            @click="fnEditarUsuario()"
            :disabled="!valid2"
            color="green"
            dark
            bottom
            right
            small
          >
            Guardar cambios <v-icon class="ml-2" small>mdi-pencil</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** DIALOG PARA EDICIÓN DE USUARIO */ -->
    <!-- DIALOG PARA ELIMINACIÓN DE USAURIO -->
    <v-dialog
      v-model="dialog_eliminar_usuario"
      width="50%"
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog_eliminar_usuario = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="editUsuario">{{
          deleteUsuario.usuarioAlias
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog_eliminar_usuario = false">
            Cerrar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!-- INICIO FORMULARIO EDICION DE USUARIO -->
      <v-card v-if="deleteUsuario != null">
        <v-card-title class="text-h5">
          ¿Desea dar de baja al usuario "{{ deleteUsuario.usuarioAlias }}"?
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <div class="mb-2">
                Dar de baja a este usuario es una acción irreversible y ya no
                tendrá ningún acceso a las funciones dentro del panel
                administrativo y aplicación móvil
              </div>

              <div>
                Nombre: <strong>{{ deleteUsuario.usuarioNombre }}</strong>
              </div>
              <div v-if="deleteUsuario.rol">
                Rol:
                <strong>{{ fnGetRolDescription(deleteUsuario.rol) }}</strong>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            @click="fnDarDeBajaUsuario()"
            color="red"
            dark
            bottom
            right
            small
          >
            Dar de baja <v-icon class="ml-2" x-small>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            bottom
            right
            small
            color=" darken-1"
            @click="reset, (dialog_eliminar_usuario = false)"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG PARA ELIMINACIÓN DE USAURIO -->
    <!-- DIALOG PARA CREAR USUARIO -->
    <v-dialog
      v-model="dialog_crear_usuario"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      max-width="40%"
    >
      <v-card>
        <v-card-title> Crear nuevo usuario </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  @keydown.space.prevent
                  v-model="newUsuario.usuarioAlias"
                  label="Alias"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newUsuario.usuarioNombre"
                  label="Nombre del usuario"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newUsuario.usuarioEmail"
                  label="Correo electrónico"
                  @keydown.space.prevent
                ></v-text-field>

                <v-select
                  v-model="newUsuario.rol"
                  :items="roles"
                  item-text="rol_descripcion"
                  item-value="rol_id"
                  :rules="[(v) => v != null || 'Este campo es obligatorio']"
                  label="Rol de usuario"
                  required
                >
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    <div style="width: 100%" :title="data.item.restricciones">
                      {{ data.item.rol_descripcion }}
                    </div>
                  </template>
                </v-select>
                <v-text-field
                  :type="fieldTypes.password"
                  v-model="newUsuario.usuarioPassword"
                  label="Contraseña"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>

                <v-btn
                  right
                  class="float-right"
                  :disabled="!valid"
                  color="success"
                  @click="fnCrearUsuario()"
                >
                  Agregar
                </v-btn>
                <v-btn class="float-right mr-4" color="error" @click="reset">
                  Cancelar
                </v-btn>

                <!-- <v-btn color="warning" @click="resetValidation">
                    Reset Validation
                  </v-btn> -->
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG PARA CREAR USUARIO -->
    <!-- DIALOG PARA EDICIÓN DE INFORMACION DE EMRPESA-->
    <v-dialog
      v-if="current_user.rol == 0"
      v-model="dialog_editar_empresa_info"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      max-width="40%"
    >
      <v-card>
        <v-card-title> Editar información de la empresa </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form
                ref="form_editar_empresa_info"
                v-model="valid_edit_empresa"
                lazy-validation
              >
                <v-text-field
                  @keydown.space.prevent
                  v-model="editar_emrpesa_info_empresa"
                  label="Nombre de la empresa"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="editar_emrpesa_info_direccion"
                  label="Dirección"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="editar_emrpesa_info_telefono"
                  label="Teléfono"
                  :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  required
                ></v-text-field>

                <v-btn
                  right
                  class="float-right"
                  :disabled="!valid_edit_empresa"
                  color="success"
                  @click="fnEditarInformacionEmpresa()"
                >
                  Editar
                </v-btn>
                <v-btn
                  class="float-right mr-4"
                  color="error"
                  @click="reset_edit_empresa()"
                >
                  Cancelar
                </v-btn>

                <!-- <v-btn color="warning" @click="resetValidation">
                    Reset Validation
                  </v-btn> -->
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG PARA EDICIÓN DE INFORMACION DE EMRPESA-->
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  computed: {
    current_user() {
      return this.$store.state.auth.payload.gpsusuario;
    },
    roles() {
      return this.$store.state.roles;
    },
  },
  data() {
    return {
      /**
       * variables para editar informacion de empresa
       */
      dialog_editar_empresa_info: false,
      editar_emrpesa_info_empresa: null,
      editar_emrpesa_info_direccion: null,
      editar_emrpesa_info_telefono: null,
      /**
       * variables para mostrar imagen
       */
      direccion_hover: false,

      /**
       * variables de edicion de contraseña
       */
      check_editar_contraseña: false,
      edit_usuarioPassword: null,
      repeat_usuarioPassword: null,
      /**
       * variables para edición de usuario
       */
      editUsuario: {
        usuarioAlias: null,
        usuarioNombre: null,
        usuarioEmail: null,
        rol: null,
      },
      deleteUsuario: {
        usuarioAlias: null,
        usuarioNombre: null,
        usuarioEmail: null,
        rol: null,
      },
      dialog_crear_usuario: false,
      dialog_editar_usuario: false,
      dialog_eliminar_usuario: false,
      /**
       * asignación de variable para que no exista autocomplete en el formulario para
       * crear nuevo usuario
       */
      fieldTypes: {
        // add this for change input type
        password: 'text',
      },
      /** fromulario creación de usuario */
      valid: true,
      valid2: true,
      valid_edit_empresa: true,
      newUsuario: {
        usuarioAlias: null,
        usuarioNombre: null,
        usuarioEmail: null,
        usuarioPassword: null,
        rol: 3,
      },
      loadingTBL: false,
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: '_id',
        },
        {
          text: 'Usuario',
          align: 'start',
          sortable: false,
          value: 'usuarioAlias',
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'usuarioNombre',
        },
        {
          text: 'Rol',
          align: 'start',
          sortable: false,
          value: 'rol',
        },
        {
          text: 'Creado',
          value: 'createdAt',
          sortable: true,
        },
        {
          text: 'Actualizado',
          value: 'updatedAt',
          sortable: true,
        },
        { text: '', value: 'actions', sortable: false },
      ],
      tblRegs: [],
      tblTotalRegs: 0,
      options: {},
      /**pagination */
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
    };
  },
  watch: {
    newUsuario: {
      handler(val) {
        console.log(val);
        if (val.usuarioPassword != null) {
          this.fieldTypes.password = 'password';
        } else {
          this.fieldTypes.password = 'text';
        }
      },
      deep: true,
    },
  },
  methods: {
    /** asignacion de variable para editar informacion de empresa */
    fnShowDialogEditarEmpreaInfo() {
      this.editar_emrpesa_info_empresa = this.current_user.plan.empresa;
      this.editar_emrpesa_info_direccion = this.current_user.plan.direccion;
      this.editar_emrpesa_info_telefono = this.current_user.plan.telefono;
      this.dialog_editar_empresa_info = true;
    },

    copyToClipBoard(textToCopy) {
      this.$notify({
        group: 'auth',
        type: 'notify',
        title: 'ID de usuario copiado',
        text: `ID: ${textToCopy}`,
        duration: 3000,
      });
      navigator.clipboard.writeText(textToCopy);
    },
    /** funcion para obtener color segun estado */

    fnGetRolDescription(rol) {
      let store_roles = this.$store.state.roles.filter(
        (store_rol) => store_rol.rol_id == rol
      );

      return store_roles[0].rol_descripcion;
      //
    },
    // formulario para creación de usuario
    fnCrearUsuario() {
      if (this.$refs.form.validate()) {
        this.crearUsuario(this.newUsuario)
          .then((r) => {
            this.fnFindRegsForTable();
            this.reset();

            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Usuario creado',
              text: 'Se ha creado el usuario:' + r.usuarioAlias + '',
              duration: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              group: 'foo',
              type: 'red',
              title: 'Error al crear usuario',
              text: `${e}`,
              duration: 3000,
            });
          });
      }
    },
    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.dialog_crear_usuario = false;
    },
    reset_edit_empresa() {
      if (this.$refs.form_editar_empresa_info) {
        this.$refs.form_editar_empresa_info.reset();
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    //función para obtener un formato de fecha legible para el usuario
    fnGetDate(date) {
      let formatedDate = moment(date).format('DD-MM-YYYY hh:mm a');
      return formatedDate;
    },
    fnGetDateUtc(date) {
      let formatedDate = moment(date).utc().format('DD-MM-YYYY hh:mm a');
      return formatedDate;
    },
    //funcion que cambia la cantidad de registros que se muestren por pagina
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.fnFindRegsForTable();
    },
    /** funcion que cambia los registros al cambiar de pagina */
    handlePageChange(value) {
      this.page = value;
      this.fnFindRegsForTable();
    },
    // API usuarios
    ...mapActions('Usuarios', {
      getUsuarios: 'get',
      findUsuarios: 'find',
      patchUsuario: 'patch',
      crearUsuario: 'create',
    }),
    getParams() {
      const params = {};

      /** buscar solo usuarios que pertenezcan al mismo plan y que esten habilitados */
      params['status'] = true;
      params['plan'] = this.$store.state.auth.payload.gpsusuario.plan._id;

      /**
       * $SORT param
       */
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? 1 : -1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { createdAt: -1 };
      }

      /**
       * paramentros de búsqueda por paginación (obligatorios)
       */
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;

      /**
       * fin de parametros para búsqueda de Guias
       */
      return params;
    },
    // obtener todos los registros para mostrar en tabla segun parametros
    fnFindRegsForTable() {
      this.loadingTBL = true;
      this.findUsuarios({
        query: this.getParams(),
      }).then((r) => {
        console.log('usuarios del plan', r);
        this.tblRegs = r.data;
        this.tblTotalRegs = r.total;
        this.loadingTBL = false;
        this.totalPages = Math.ceil(
          parseInt(r.total) / parseInt(this.pageSize)
        );
      });
    },

    // funcion para editar usuario
    fnAssignEditUsuario(usuario) {
      // asignar informacion del usuario a editiar
      this.check_editar_contraseña = false;
      this.repeat_usuarioPassword = null;
      this.edit_usuarioPassword = null;
      this.editUsuario = Object.assign({}, usuario, {});

      // asignar rol seleccionado a editar
      let store_roles = this.$store.state.roles.filter(
        (store_rol) => store_rol.rol_id == usuario.rol
      );
      this.editUsuario.rol = store_roles[0];

      // mostrar formulario para edición de usuario
      this.dialog_editar_usuario = true;
    },

    fnEditarUsuario() {
      /** asignacion de variable temporal para cuerpo de edicion de  usuario */
      let temp_editUsuario = this.editUsuario;

      /** validación de cambio de contraseña */
      if (this.check_editar_contraseña == true) {
        console.log('se va modificar la contraseña');
        if (this.edit_usuarioPassword != this.repeat_usuarioPassword) {
          this.$refs.repeat_usuarioPassword.focus();
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Las contraseñas no coinciden',
            text: 'Por favor vuelve a ingresar la contraseña',
            duration: 3000,
          });
          return false;
        } else if (
          this.edit_usuarioPassword == '' ||
          this.edit_usuarioPassword == null
        ) {
          this.$refs.edit_usuarioPassword.focus();
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'La contraseña no puede ir vacía',
            text: 'Por favor ingresar una nueva contraseña',
            duration: 3000,
          });
          return false;
        } else {
          temp_editUsuario.usuarioPassword = this.edit_usuarioPassword;
        }
      }

      //rol se maneja como un objeto, extracción de su id para guardado en base de datos
      temp_editUsuario.rol = this.editUsuario.rol.rol_id;

      /** API CALL actualización información de usuario */
      this.patchUsuario([temp_editUsuario._id, temp_editUsuario]).then((r) => {
        console.log('usuario editado', r);
        this.dialog_editar_usuario = false;

        this.$notify({
          group: 'foo',
          type: 'success',
          title: 'Usuario editado',
          text:
            'El usuario: ' + this.editUsuario.usuarioNombre + ' se ha editado.',
          duration: 3000,
        });
      });
    },
    fnAssignEliminarusuario(usuario) {
      this.deleteUsuario = Object.assign({}, usuario, {});
      this.dialog_eliminar_usuario = true;
    },
    fnDarDeBajaUsuario() {
      this.patchUsuario([this.deleteUsuario._id, { status: 0 }]).then((r) => {
        this.fnFindRegsForTable();
        this.$notify({
          group: 'foo',
          type: 'success',
          title: 'Usuario eiiminado',
          text: `Se ha eliminado al usuario ${this.deleteUsuario.usuarioNombre}`,
          duration: 3000,
        });
        this.dialog_eliminar_usuario = false;
        this.deleteUsuario = Object.assign(
          {},
          {
            usuarioAlias: null,
            usuarioNombre: null,
            usuarioEmail: null,
            rol: null,
          },
          {}
        );
      });
    },
  },
  mounted() {
    this.fnFindRegsForTable();
    this.reset();
  },
};
</script>
